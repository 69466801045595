import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div className="inner-footer">
        <div className="footer-logo">
          <h3 id="natural-logo">🌿 Natural</h3>
          <div className="contact-link"><a href="mailto:founders@naturalui.com">Contact us</a></div>
          <div className="contact-link"><a href="https://twitter.com/joinnatural" target="_blank" rel="noopener noreferrer">Twitter</a></div>
          <div className="contact-link"><a href="https://www.linkedin.com/company/naturalui" target="_blank" rel="noopener noreferrer">LinkedIn</a></div>
        </div>
        <div className="input-email-form-2">
          <form className="email-form-2" onSubmit={(e) => e.preventDefault()}>
            <div className="input-button-group">
              <input
                id="email-2"
                type="email"
                placeholder="your@email.com"
                required
              />
              <button type="submit" className="input-button">
                Join Waitlist
              </button>
            </div>
          </form>
        </div>
      </div>
    </footer>
  );
}

export default Footer;