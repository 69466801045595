import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import BlogMain from './pages/Blog/BlogMain';
import BlogPost from './pages/Blog/BlogPost';
import ScrollToTop from './components/ScrollToTop';
import Minno from './pages/Minno/Minno';
import Example from './pages/SEO-pages/Example';
import Finance from './pages/SEO-pages/finance';

//Import Mixpanel SDK
import mixpanel from "mixpanel-browser";
 
// Near entry of your product, init Mixpanel
mixpanel.init("9d2d3daa6e571f4e48cab49525ee515a", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/minno" element={<Minno />} />
        <Route path="/blog" element={<BlogMain />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/example" element={<Example />} />
        <Route path="/finance" element={<Finance />} />
      </Routes>
    </Router>
  );
}

export default App;
