import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FaArrowLeft } from 'react-icons/fa';
import { Toaster, toast } from 'react-hot-toast';
import Confetti from 'react-confetti';
import blogPosts from './blogPosts';
import './BlogPost.css';
import Navbar from '../../components/Navbar';
import ImageWithFallback from '../../components/ImageWithFallback';
import Footer from '../../components/Footer';

function BlogPost() {
  const { slug } = useParams();
  const [content, setContent] = useState('');
  const [email, setEmail] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const post = blogPosts.find(post => post.slug === slug);
  const navigate = useNavigate();
  const location = useLocation();

  const generateId = (text) => {
    // This will preserve the exact format used in the markdown file
    return text.toLowerCase().replace(/\s+/g, '-');
  };

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      toast.success('Link copied to clipboard!', {
        style: {
          backgroundColor: '#0C1B09',
          color: '#7FF257',
          border: '1px solid #3D493A',
        },
        iconTheme: {
          primary: '#7FF257',
          secondary: '#0C1B09',
        },
      });
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
      toast.error('Failed to copy link', {
        style: {
          backgroundColor: '#0C1B09',
          color: '#FF6C3D',
          border: '1px solid #3D493A',
        },
        iconTheme: {
          primary: '#FF6C3D',
          secondary: '#0C1B09',
        },
      });
    });
  }, []);

  const scrollToHeading = useCallback(() => {
    if (location.hash) {
      const id = location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    if (post) {
      console.log('Fetching content for:', post.content);
      fetch(`/blog-content/${post.content}`)
        .then(response => {
          console.log('Response status:', response.status);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.text();
        })
        .then(text => {
          console.log('Fetched content:', text.substring(0, 100) + '...');
          setContent(text);
          // Scroll to heading after content is loaded
          setTimeout(scrollToHeading, 100);
        })
        .catch(error => console.error('Error fetching blog content:', error));
    }
  }, [post, scrollToHeading]);

  // Add an effect to handle hash changes
  useEffect(() => {
    scrollToHeading();
  }, [location.hash, scrollToHeading]);

  if (!post) {
    return <div>Post not found</div>;
  }

  const handleGoBack = () => {
    navigate('/blog'); // This will always navigate to the main blog page
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Show confetti and notification immediately
    setShowConfetti(true);
    toast.success('Signup successful!', {
      style: {
        backgroundColor: '#0C1B09',
        color: '#7FF257',
        border: '1px solid #3D493A',
      },
      iconTheme: {
        primary: '#7FF257',
        secondary: '#0C1B09',
      },
    });
  
    // Send the data to Google Sheets in the background
    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbx4u2_GxoTyl7gZQj5eRLk1uwH6TaHh1MkqY5PmDdqHMejEiC4aADvkWTGnuEWtiUB3pA/exec', {
        method: 'POST',
        body: new URLSearchParams({ email }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  
    // Reset the form
    setEmail('');
  
    // Stop confetti after a delay
    setTimeout(() => {
      setShowConfetti(false);
    }, 3000); // 3 seconds
  };

  return (
    <div className="blog-post">
      <Navbar onCopyLink={handleCopyLink} />
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 3000,
        }}
      />
      {showConfetti && <Confetti />}
      <header className="blog-post-header">
        <div className="go-back-btn-container">
          <button className="go-back-btn" onClick={handleGoBack}>
            <FaArrowLeft /> Go back
          </button>
        </div>
        <div className="blog-post-info">
          <h1>{post.title}</h1>
          <div className="author-info">
            <ImageWithFallback 
              src={post.authorImage} 
              alt={post.author} 
              className="author-image"
            />
            <p>{post.author} • {post.date}</p>
          </div>
        </div>
      </header>
      <div className="blog-post-content-wrapper">
        <div className="blog-post-content">
          <ReactMarkdown 
            className="blog-content"
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
              code({node, inline, className, children, ...props}) {
                const match = /language-(\w+)/.exec(className || '')
                return !inline && match ? (
                  <SyntaxHighlighter
                    style={vscDarkPlus}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                  >
                    {String(children).replace(/\n$/, '')}
                  </SyntaxHighlighter>
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                )
              },
              h1: ({node, ...props}) => <h1 id={generateId(props.children.toString())} {...props} />,
              h2: ({node, ...props}) => <h2 id={generateId(props.children.toString())} {...props} />,
              h3: ({node, ...props}) => <h3 id={generateId(props.children.toString())} {...props} />,
              h4: ({node, ...props}) => <h4 id={generateId(props.children.toString())} {...props} />,
              a: ({node, href, ...props}) => {
                if (href && href.startsWith('#')) {
                  return <a href={href} onClick={(e) => {
                    e.preventDefault();
                    const targetId = href.substring(1);
                    const targetElement = document.getElementById(targetId);
                    if (targetElement) {
                      targetElement.scrollIntoView({ behavior: 'smooth' });
                    }
                  }} {...props} />;
                }
                return <a href={href} {...props} />;
              },
            }}
          >
            {content}
          </ReactMarkdown>
        </div>
        <aside className="blog-post-cta">
          <div className="cta-wrapper">
            <div className="cta-box-email">
              <h3>Get the future sent to your inbox 🔥</h3>
              <form onSubmit={handleSubmit} className="cta-email-form">
                <input
                  type="email"
                  placeholder="your@email.com"
                  className="cta-email-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className="cta-email-button">Sign up</button>
              </form>
            </div>
            <div className="cta-box-natural">
              <h3>Natural</h3>
              <p>Take any action in seconds</p>
              <Link to="/" className="cta-learn-more-btn">Learn more</Link>
            </div>
          </div>
        </aside>
      </div>
      <Footer />
    </div>
  );
}

export default BlogPost;