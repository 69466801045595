import React from 'react';
import { Link } from 'react-router-dom';
import { FaLink } from 'react-icons/fa';

function Navbar({ onCopyLink }) {
  const handleCopyLink = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onCopyLink();
  };

  return (
    <header className="App-header">
      <div className="header-content">
        <div className="logo-header">
          <Link to="/">
            <h3 id="natural-logo">🌿 Natural</h3>
          </Link>
        </div>
        <div className="nav-actions">
          <button id="copy-link" className="icon-button" onClick={handleCopyLink}>
            <FaLink />
          </button>
          <div className="contact-link">
            <Link to="/blog" className="contact-link">Blog</Link>
          </div>
          <div className="contact-link">
            <a href="mailto:founders@naturalui.com">Contact us</a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;