import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import blogPosts from './blogPosts';
import './BlogMain.css';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import ImageWithFallback from '../../components/ImageWithFallback';
import blog1Image from './images/blog1.png';  // Changed this line
import { Toaster } from 'react-hot-toast';
import { toast } from 'react-hot-toast';

function BlogMain() {
  const mainHighlight = blogPosts.find(post => post.mainHighlight);
  const otherHighlights = blogPosts.filter(post => post.highlighted && !post.mainHighlight).slice(0, 2);
  const latestPosts = blogPosts.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 4);

  const scrollingText = "Insights from the frontier of software development. \u00A0\u00A0\u00A0\u00A0 ";

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      toast.success('Link copied to clipboard!', {
        style: {
          backgroundColor: '#0C1B09',
          color: '#7FF257',
          border: '1px solid #3D493A',
        },
        iconTheme: {
          primary: '#7FF257',
          secondary: '#0C1B09',
        },
      });
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
      toast.error('Failed to copy link', {
        style: {
          backgroundColor: '#0C1B09',
          color: '#FF6C3D',
          border: '1px solid #3D493A',
        },
        iconTheme: {
          primary: '#FF6C3D',
          secondary: '#0C1B09',
        },
      });
    });
  }, []);

  return (
    <div className="blog-main">
      <Navbar onCopyLink={handleCopyLink} />
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 3000,
        }}
      />
      <div className="blog-banner">
        <div className="blog-banner-content">
          {scrollingText.repeat(20)}
        </div>
      </div>
      <main>
        <h1 id="blog-title">Natural blog</h1>
        
        <Link to={`/blog/${mainHighlight.slug}`} className="main-highlight-link">
          <section className="main-highlight">
            <div className="main-highlight-content">
              <h2>{mainHighlight.title}</h2>
              <div className="author-info">
                <ImageWithFallback 
                  src={mainHighlight.authorImage} 
                  alt={mainHighlight.author} 
                  className="author-image"
                  width={28}
                  height={28}
                />
                <p>
                  <span className="author-name">{mainHighlight.author}</span>
                  <span className="author-date-separator">•</span>
                  <span>{mainHighlight.date}</span>
                </p>
              </div>
              <span className="read-more-btn">Read more</span>
            </div>
            <div className="main-highlight-image">
              <ImageWithFallback 
                src={mainHighlight.mainHighlight ? blog1Image : mainHighlight.image} 
                alt={mainHighlight.title} 
              />
            </div>
          </section>
        </Link>

        <section className="other-highlights">
          {otherHighlights.map(post => (
            <Link key={post.slug} to={`/blog/${post.slug}`} className="highlight-post">
              <div className="highlight-post-image">
                <ImageWithFallback src={post.image} alt={post.title} />
              </div>
              <div className="highlight-post-content">
                <h3>{post.title}</h3>
                <div className="author-info">
                  <ImageWithFallback 
                    src={post.authorImage} 
                    alt={post.author} 
                    className="author-image"
                    width={28}
                    height={28}
                  />
                  <p>
                    <span className="author-name">{post.author}</span>
                    <span className="author-date-separator">•</span>
                    <span>{post.date}</span>
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </section>

        <section className="latest-posts">
          <h2>Latest posts</h2>
          <div className="latest-posts-grid">
            {latestPosts.map(post => (
              <Link key={post.slug} to={`/blog/${post.slug}`} className="latest-post">
                <h3>{post.title}</h3>
                <div className="author-info">
                  <ImageWithFallback 
                    src={post.authorImage} 
                    alt={post.author} 
                    className="author-image"
                    width={28}
                    height={28}
                  />
                  <p>
                    <span className="author-name">{post.author}</span>
                    <span className="author-date-separator">•</span>
                    <span>{post.date}</span>
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default BlogMain;