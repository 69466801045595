import React, { useState, useEffect, useRef } from 'react';
import { FaRedo, FaChevronDown } from 'react-icons/fa';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import '../App.css';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DemoStates = {
  INITIAL: 'INITIAL',
  TABLE_SHOWN: 'TABLE_SHOWN',
  JEN_FELL_THROUGH: 'JEN_FELL_THROUGH'
};

const DemoComponent = ({ onShowToast, filter }) => {
  const [currentState, setCurrentState] = useState(DemoStates.INITIAL);
  const [inputText, setInputText] = useState("What clients closed q2 '24?");
  const [tableData, setTableData] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [headingText, setHeadingText] = useState("");  // New state for heading
  const [placeholder, setPlaceholder] = useState("");
  const [previousInput, setPreviousInput] = useState("");
  const componentRef = useRef(null);
  const contentRef = useRef(null);
  const inputRef = useRef(null);
  const cursorRef = useRef(null);

  const filterTexts = {
    crm: {
      heading: "Hey there! 👋 I'm your CRM assistant.",
      placeholder: "Ask me about your clients, deals, or sales data...",
      initialInput: "What clients closed q2 '24?",
      tableData: [
        { name: "Tim Apple", status: "closed", date: "5/7/24" },
        { name: "Gavin Belson", status: "closed", date: "5/7/24" },
        { name: "Richard Hendricks", status: "closed", date: "5/7/24" }
      ],
      updateText: "actually, Richard fell through..",
      updateStatus: { name: "Richard Hendricks", newStatus: "cold" }
    },
    erp: {
      heading: "Hello! 👋 I'm your ERP assistant.",
      placeholder: "Ask me about inventory, orders, or financials...",
      initialInput: "What's our current inventory?",
      chartData: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [{
          label: 'All Products',
          data: [140, 320, 390, 470, 640, 750],
          borderColor: 'rgba(255, 222, 105, 1)',
          backgroundColor: 'rgba(255, 222, 105, 0.2)',
          tension: 0.1
        }]
      },
      updateText: "What about just apples?",
      updateFilter: 'Apples'
    },
    finance: {
      heading: "Hi there! 👋 I'm your finance assistant.",
      placeholder: "Ask me about financial reports, stocks, or market data...",
      initialInput: "Show me Netflix and their competitors PE ratio",
      tableData: [
        { company: "Netflix", "PE ratio": 32.5 },
        { company: "Disney", "PE ratio": 40.2 },
        { company: "Amazon", "PE ratio": 58.7 },
        { company: "Apple TV+", "PE ratio": 28.1 }
      ],
      updateText: "Add these to a list called 'movies'",
      updateStatus: {
        message: "Stocks added to 'movies' list"
      }
    },
    mobile: {
      heading: "Hey! 👋 I'm your e-commerce assistant.",
      placeholder: "Ask me about product details, inventory, or updates...",
      initialInput: "Show me the description for our bestselling t-shirt",
      tableData: [
        { product: "Classic Cotton Tee", description: "A comfortable, versatile t-shirt made from 100% organic cotton. Available in various colors and sizes.", price: "$24.99", inventory: "150" }
      ],
      updateText: "Update the description to highlight its eco-friendly material",
      updateStatus: {
        product: "Classic Cotton Tee",
        newDescription: "Our bestselling eco-friendly t-shirt made from 100% organic cotton. Soft, durable, and kind to the environment. Available in various colors and sizes."
      }
    }
  };

  useEffect(() => {
    const filterText = filterTexts[filter] || filterTexts.crm;
    setCurrentState(DemoStates.INITIAL);
    setTableData(null);
    setHeadingText(filterText.heading);
    setPlaceholder(filterText.placeholder);
    setInputText(filterText.initialInput);
    setClickCount(0);
  }, [filter]);

  const handleTryIt = (e) => {
    e.stopPropagation();
    setClickCount(prevCount => prevCount + 1);
    const filterText = filterTexts[filter];

    switch (currentState) {
      case DemoStates.INITIAL:
        setPreviousInput(inputText);
        setCurrentState(DemoStates.TABLE_SHOWN);
        setInputText(filterText.updateText);
        setTableData(filter === 'erp' ? filterText.chartData : filterText.tableData);
        setHeadingText(filterText.initialInput);
        break;
      case DemoStates.TABLE_SHOWN:
        setPreviousInput(inputText);
        setCurrentState(DemoStates.JEN_FELL_THROUGH);
        if (filter === 'crm') {
          setTableData(prevData => prevData.map(row => 
            row.name === filterText.updateStatus.name
              ? { ...row, status: filterText.updateStatus.newStatus }
              : row
          ));
        } else if (filter === 'erp') {
          setTableData(prevData => ({
            ...prevData,
            datasets: [
              ...prevData.datasets,
              {
                label: 'Apples',
                data: [10, 60, 140, 200, 230, 300],
                borderColor: 'rgba(127, 242, 87, 1)',
                backgroundColor: 'rgba(127, 242, 87, 0.2)',
                tension: 0.1
              }
            ]
          }));
        } else if (filter === 'finance') {
          // Don't modify the table data, just show the toast
          onShowToast(
            <div className="toast-content">
              <IoCheckmarkCircleSharp className="toast-icon" />
              <span>{filterText.updateStatus.message}</span>
            </div>
          );
        } else if (filter === 'mobile') {
          setTableData(prevData => prevData.map(row => 
            row.product === filterText.updateStatus.product
              ? { ...row, description: filterText.updateStatus.newDescription }
              : row
          ));
        }
        setHeadingText(filterText.updateText);
        setInputText("");
        setPlaceholder("Type here...");
        onShowToast(
          <div className="toast-content">
            <IoCheckmarkCircleSharp className="toast-icon" />
            <span>{filter === 'crm' ? 'Client status updated' : filter === 'erp' ? 'Inventory filtered' : filter === 'finance' ? filterText.updateStatus.message : 'Product description updated'}</span>
          </div>
        );
        break;
      default:
        break;
    }
  };

  const handleRestart = (e) => {
    e.stopPropagation();
    const filterText = filterTexts[filter];
    setCurrentState(DemoStates.INITIAL);
    setInputText(filterText.initialInput);
    setTableData(null);
    setHeadingText(filterText.heading);
    setPlaceholder(filterText.placeholder);
    setPreviousInput("");
  };

  useEffect(() => {
    if (contentRef.current) {
      const height = contentRef.current.scrollHeight;
      contentRef.current.style.maxHeight = `${height}px`;
    }
  }, [currentState, tableData]);

  const getStatusTagClass = (status) => {
    return status.toLowerCase() === 'closed' ? 'status-tag-closed' : 'status-tag-open';
  };

  useEffect(() => {
    const updateCursorPosition = () => {
      if (inputRef.current && cursorRef.current) {
        const textWidth = inputText ? getTextWidth(inputText, getComputedStyle(inputRef.current).font) : 0;
        cursorRef.current.style.left = `${textWidth + 14}px`; // 14px is the left padding of the input
      }
    };

    updateCursorPosition();
  }, [inputText]);

  const getTextWidth = (text, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    return context.measureText(text).width;
  };

  return (
    <div 
      ref={componentRef}
      className={`demo-component ${currentState} open ${filter}`}
    >
      <div 
        ref={contentRef}
        className="demo-component-content"
      >
        <h3 className="demo-component-heading">
          <span className="wave-emoji">👋</span>
          What do you want to do?
        </h3>
        <div className="demo-input-wrapper">
          <input 
            ref={inputRef}
            type="text" 
            className="demo-input" 
            value={inputText} 
            placeholder={placeholder}
            readOnly 
          />
          <div ref={cursorRef} className="blinking-cursor"></div>
        </div>
        {currentState !== DemoStates.JEN_FELL_THROUGH ? (
          <button 
            className={`demo-try-button ${clickCount < 2 ? 'pulse' : ''}`} 
            onClick={handleTryIt}
          >
            Try it
          </button>
        ) : (
          <button className="demo-restart-button" onClick={handleRestart}>
            <FaRedo className="restart-icon" />
          </button>
        )}
        {previousInput && currentState !== DemoStates.INITIAL && (
          <h3 className="previous-input">
             "{previousInput}"
          </h3>
        )}
        {tableData && currentState !== DemoStates.INITIAL && (
          <div className="demo-table">
            {filter === 'erp' && tableData.datasets ? (
              <div style={{ height: '250px' }}>
                <Line
                  data={tableData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    layout: {
                      padding: {
                        left: 10,
                        right: 10,
                        top: 0,
                        bottom: 10
                      }
                    },
                    scales: {
                      y: {
                        beginAtZero: true,
                        ticks: {
                          color: '#FFFFFF',
                          font: {
                            size: 12
                          },
                          align: 'start'
                        },
                        grid: {
                          color: 'rgba(255, 255, 255, 0.1)'
                        },
                        position: 'left'
                      },
                      x: {
                        ticks: {
                          color: '#FFFFFF',
                          font: {
                            size: 12
                          },
                          align: 'start'
                        },
                        grid: {
                          color: 'rgba(255, 255, 255, 0.1)'
                        }
                      }
                    },
                    plugins: {
                      legend: {
                        display: true,
                        position: 'top',
                        align: 'start',
                        labels: {
                          color: '#FFFFFF',
                          font: {
                            size: 14
                          },
                          boxWidth: 15,
                          padding: 10
                        }
                      },
                      title: {
                        display: true,
                        text: 'Inventory Levels',
                        color: '#FFFFFF',
                        font: {
                          size: 18
                        },
                        padding: {
                          top: 10,
                          bottom: 20
                        },
                        align: 'start'
                      }
                    }
                  }}
                />
              </div>
            ) : (
              Array.isArray(tableData) && tableData.length > 0 && (
                <table>
                  <thead>
                    <tr>
                      {Object.keys(tableData[0]).map((key, index) => (
                        <th key={index}>{key.charAt(0).toUpperCase() + key.slice(1)}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((row, index) => (
                      <tr key={index}>
                        {Object.values(row).map((value, cellIndex) => (
                          <td key={cellIndex}>
                            {typeof value === 'string' && ['closed', 'cold', 'On', 'Off'].includes(value) ? (
                              <span className={`status-tag ${getStatusTagClass(value)}`}>
                                {value}
                              </span>
                            ) : value}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )
            )}
          </div>
        )}
        {currentState === DemoStates.INITIAL && (
          <div className="demo-dropdown">
            <span>Recent searches</span>
            <FaChevronDown className="dropdown-arrow" />
          </div>
        )}
      </div>
    </div>
  );
};

export default DemoComponent;