import '../../App.css';

import React, { useState, useCallback, useRef, useEffect } from 'react';
import Confetti from 'react-confetti';
import { Toaster, toast } from 'react-hot-toast';
import RotatingTextBox from '../../components/RotatingTextBox';
import leapyearLogo from '../../images/leapyear.png';
import pioneerLogo from '../../images/pioneer.png';
import devIcon from '../../images/dev.png';
import locationIcon from '../../images/location.png';
import chartIcon from '../../images/chart.png';
import helpIcon from '../../images/help.png';
import fastIcon from '../../images/fast.png';
import gearIcon from '../../images/gear.png';
import bulbIcon from '../../images/bulb.png';
import mobileIcon from '../../images/mobile.png';
import saveIcon from '../../images/save.png';
import heartIcon from '../../images/heart.png';
import { FaLink } from 'react-icons/fa';
import Navbar from '../../components/Navbar';
import { Link } from 'react-router-dom';
import packedCRM from '../../images/packedcrm.png';
import DemoComponent from '../../components/DemoComponent';
import minnoLogo from '../../images/minno.png';
import adminDemo from '../../images/admindemo.png';
import financeDemoImage from '../../images/financedemo.png'; // Import the analytics demo image
import mobileDemoImage from '../../images/mobiledemo.png'; // Import the support demo image
import erpDemoImage from '../../images/erpdemo.png'; // Import the support demo image

function Home() {
  const [email, setEmail] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [demoToast, setDemoToast] = useState(null);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const toastTimeoutRef = useRef(null);
  const [selectedFilter, setSelectedFilter] = useState('crm');


  const handleFilterChange = (filter) => {
    setSelectedFilter(filter.toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Show confetti and notification immediately
    setShowConfetti(true);
    toast.success('Signup successful!', {
      position: 'top-center',
      style: {
        backgroundColor: '#0C1B09',
        color: '#7FF257',
        border: '1px solid #3D493A',
      },
      iconTheme: {
        primary: '#7FF257',
        secondary: '#0C1B09',
      },
    });
  
    // Send the data to Google Sheets in the background
    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbx4u2_GxoTyl7gZQj5eRLk1uwH6TaHh1MkqY5PmDdqHMejEiC4aADvkWTGnuEWtiUB3pA/exec', {
        method: 'POST',
        body: new URLSearchParams({ email }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      toast.error('There was an error submitting the form.');
    }
  
    // Reset the form
    setEmail('');
  
    // Stop confetti after a delay
    setTimeout(() => {
      setShowConfetti(false);
    }, 3000); // 3 seconds
  };

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      toast.success('Link copied to clipboard!', {
        style: {
          backgroundColor: '#0C1B09',
          color: '#7FF257',
          border: '1px solid #3D493A',
        },
        iconTheme: {
          primary: '#7FF257',
          secondary: '#0C1B09',
        },
      });
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
      toast.error('Failed to copy link', {
        style: {
          backgroundColor: '#0C1B09',
          color: '#FF6C3D',
          border: '1px solid #3D493A',
        },
        iconTheme: {
          primary: '#FF6C3D',
          secondary: '#0C1B09',
        },
      });
    });
  }, []);

  const actions = [
    {
      text: "Can you export my data?",
      toastMessage: "Data exported successfully!",
      action: () => {
      }
    },
    {
      text: "Can I share this somehow?",
      toastMessage: "Yes, we've copied the URL to your clipboard!",
      action: () => {
        navigator.clipboard.writeText(window.location.href).then(() => {
        }).catch((err) => {
          console.error('Failed to copy text: ', err);
          toast.error('Failed to copy link');
        });
      }
    },
    {
      text: "Where's the brand kit?",
      toastMessage: "Brand kit downloaded successfully!",
      action: () => {
      }
    },
    {
      text: "Can you turn off auto-play?",
      toastMessage: "Auto-play has been disabled!",
      action: () => {
      }
    },
  ];

  const handleActionPerformed = useCallback((index) => {
    const action = actions[index];
    setCurrentAction(action);
    action.action();
  }, [actions]);

  const handleActionComplete = useCallback(() => {
    setCurrentAction(null);
  }, []);

  const handleDemoToast = (message) => {
    setDemoToast(message);
    
    // Clear any existing timeouts
    if (toastTimeoutRef.current) {
      clearTimeout(toastTimeoutRef.current);
    }

    // Set a small delay before showing the toast to ensure the DOM has updated
    setTimeout(() => {
      setIsToastVisible(true);
    }, 50);

    // Set the timeout for hiding the toast
    toastTimeoutRef.current = setTimeout(() => {
      setIsToastVisible(false);
      toastTimeoutRef.current = setTimeout(() => setDemoToast(null), 300);
    }, 5000);
  };

  useEffect(() => {
    return () => {
      if (toastTimeoutRef.current) {
        clearTimeout(toastTimeoutRef.current);
      }
    };
  }, []);

  const demoImages = {
    crm: packedCRM,
    erp: erpDemoImage,
    finance: financeDemoImage,
    mobile: mobileDemoImage
  };

  return (
    <div className="home">
      <Toaster
        position="top-center"
        toastOptions={{
          success: {
            style: {
              backgroundColor: '#0C1B09',
              color: '#7FF257',
              border: '1px solid #3D493A',
            },
            iconTheme: {
              primary: '#7FF257',
              secondary: '#0C1B09',
            },
          },
          error: {
            style: {
              background: '#0C1B09',
              color: '#FF6C3D',
            },
            iconTheme: {
              primary: '#FF6C3D',
              secondary: '#0C1B09',
            },
          },
        }}
      />
      {showConfetti && <Confetti />}
      <header className="App-header">
        <Navbar onCopyLink={handleCopyLink} />
        <div className="hero">
          <div className="text-content centered">
            <h1 id="header">Finance any action in <span className="highlight">seconds</span></h1>
            <p id="sub-header">Control apps and websites with text or voice</p>
            <form onSubmit={handleSubmit} className="email-form">
              <div className="input-button-group">
                <input
                  id="email"
                  type="email"
                  placeholder="your@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className="input-button" style={{ width: '100%' }}>
                  Join Waitlist
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="founders">
          <div className="logos">
            <img src={leapyearLogo} id="leapyear" alt="Leapyear" />
            <img src={pioneerLogo} id="pioneer" alt="YC Pioneer Program" />
            <img src={minnoLogo} id="minno" alt="Minno" />
          </div>
        </div>
      </header>
      <main>
        
      <div className="demo-filters">
          <button 
            className={`filter-button crm ${selectedFilter === 'crm' ? 'active' : ''}`}
            onClick={() => handleFilterChange('crm')}
          >
            CRM
          </button>
          <button 
            className={`filter-button erp ${selectedFilter === 'erp' ? 'active' : ''}`}
            onClick={() => handleFilterChange('erp')}
          >
            ERP
          </button>
          <button 
            className={`filter-button finance ${selectedFilter === 'finance' ? 'active' : ''}`}
            onClick={() => handleFilterChange('finance')}
          >
            Finance
          </button>
          <button 
            className={`filter-button mobile ${selectedFilter === 'mobile' ? 'active' : ''}`}
            onClick={() => handleFilterChange('mobile')}
          >
            Mobile
          </button>
        </div>

        <div className={`demo-section-container ${selectedFilter}`}>
          <div className="demo-section">
            <div className="demo-content">
              {demoToast && (
                <div className={`demo-toast ${isToastVisible ? 'visible' : 'hidden'}`}>
                  {demoToast}
                </div>
              )}
              <img
                src={demoImages[selectedFilter]}
                alt={`${selectedFilter} Demo`}
                className={`demo-image ${selectedFilter === 'mobile' ? 'mobile-demo-image' : ''}`}
              />
              <DemoComponent 
                onShowToast={handleDemoToast} 
                filter={selectedFilter}
              />
            </div>
          </div>
        </div>

        <div className="features-container">
        <div className="features">
          <div className="feature-item-1">
            <img src={locationIcon} id="icon" alt="Feature Icon" />
            <h2 id="feature-heading">AI Control</h2>
            <p id="feature-text">Let Natural complete complex tasks for you.</p>
          </div>
          <div className="feature-item-1">
            <img src={saveIcon} id="icon" alt="Feature Icon" />
            <h2 id="feature-heading">Save important workflows</h2>
            <p id="feature-text">Natural remembers your most common requests.</p>
          </div>
          <div className="feature-item-1">
            <img src={helpIcon} id="icon" alt="Feature Icon" />
            <h2 id="feature-heading">Help docs are dead</h2>
            <p id="feature-text">Never waste time searching for answers.</p>
          </div>
        </div>
        
        </div>
       

        <div className="main-features">

<h2 id="features-text">Better UX, <span className="highlight">better business</span></h2>

<div className="features-section">

<div className="features-2">
  <div className="feature-item-2">
    <img src={bulbIcon} id="icon" alt="Feature Icon" />
    <h2 id="feature-heading">Product insights</h2>
    <p id="feature-text">View & analyze user prompts. Understand your users' pain points and improve your product.</p>
  </div>
    <div className="feature-item-2">
    <img src={chartIcon} id="icon" alt="Feature Icon" />
    <h2 id="feature-heading">Higher customer engagement</h2>
    <p id="feature-text">Your users will keep coming back because Natural is always there to help.</p>
  </div>

</div>


</div>

<div className="demo-section-container-2">
          <div className="demo-section-2">
            <div className="demo-content-2">
              <img src={adminDemo} alt="NaturalUI admin dashboard" className="demo-image" />
            </div>
          </div>
        </div>


</div>


<div className="dev-section-container">
<h2 id="features-text">Easy for <span className="highlight">developers</span></h2>
<div className="dev-section">

  <div className="feature-item">
    <img src={devIcon} id="icon" alt="Feature Icon" />
    <h2 id="feature-heading">Simple integration</h2>
    <p id="feature-text">Add a code snippet & configure Natural from the web.</p>
  </div>
  <div className="feature-item">
    <img src={chartIcon} id="icon" alt="Feature Icon" />
    <h2 id="feature-heading">Outperform web agents</h2>
    <p id="feature-text">Natural integrates with your frontend to call functions reliably.</p>
  </div>
  <div className="feature-item">
    <img src={devIcon} id="icon" alt="Feature Icon" />
    <h2 id="feature-heading">More maintainable than RPA</h2>
    <p id="feature-text">Functions are automatically updated in your CI/CD pipeline.</p>
  </div>

</div>

</div>

        <div className="story">
          <h1 id="story-heading">A smart <span className="highlight">task agent</span></h1>
          <div className="story-content">
            <p id="story-text">When your users are lost and frustrated, the last thing they want to do is to search for answers in outdated help docs. Natural meets users where they are and instantly knows how to complete any task.</p>
            <ul>
              <li><strong>AI Control:</strong> Users want to get things done, fast. Natural completes manual tasks for them in seconds.</li>
              <li><strong>Insights:</strong> When users struggle with your UI, it's important information for improving your product. So we surface all of it to you, while solving user pain points immediately.</li>
              <li><strong>Easy:</strong> Add a code snippet to your product (like Posthog) and configure Natural on the web.</li>
            </ul>
            <p id="story-text">Our goal is to eliminate the need for help docs, knowledge bases, and endless chats with support. Join us in our mission to make products more accessible.</p>
            <h3>Colin and Grayson</h3>
            <p>Founders of Natural</p>
          </div>
        </div>

      </main>
      <footer>
        <div className="inner-footer">
          <div className="footer-logo">
            <h3 id="natural-logo">🌿 Natural</h3>
            <div className="contact-link"><a href="mailto:founders@naturalui.com">Contact us</a></div>
            <div className="contact-link"><a href="https://twitter.com/joinnatural" className="contact-link" target="_blank" rel="noopener noreferrer">Twitter</a></div>
             <div className="contact-link"><a href="https://www.linkedin.com/company/naturalui" className="contact-link" target="_blank" rel="noopener noreferrer">LinkedIn</a></div>
          </div>
          <div className="input-email-form-2">
            <form onSubmit={handleSubmit} className="email-form-2">
              <div className="input-button-group">
                <input
                  id="email-2"
                  type="email"
                  placeholder="your@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className="input-button">
                  Join Waitlist
                </button>
              </div>
            </form>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;